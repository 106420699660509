<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src

import wx from 'weixin-js-sdk'
import axios from 'axios'
export default {
  name: 'HomeView',
  data () {
    return {
      today: ''
    }
  },
  created () {
    console.log('created')

    // 请求后端接口获取公众号openid
    console.log(wx)
    console.log(this.$route.query)
    this.today = this.$route.query.today

    // this.jumpUrl(this.urls[2])

    // getMpUserInfo(this.$route.query.code).then(res => {
    // 跳转回小程序的登录页面并将公众号openid带过去
    // wx.miniProgram.redirectTo({ url: '/pages/home/home?=123' })
    // wx.miniProgram.switchTab({ url: '/pages/home/home' })
    // })
    // axios.config({
    //   baseUrl: 'http://kingforn.mynatapp.cc'
    // })
    axios({
      url: 'https://applet.zjstarsoft.com/api/MPCertified/',
      method: 'GET',
      params: this.$route.query
    }).then(res => {
      console.log(res)
      wx.miniProgram.redirectTo({ url: '/pages/yygh/yygh?mpOpenid=' + res.data.data.mpOpenid + '&today=' + res.data.data.today })
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
